.business-logo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
  height: 6vw;
  width: 6vw;
  img {
    // min-height: 100px;
    min-width: 100px;
    max-height: 150px;
    max-width: 150px;
  }
}
